<script setup>
import { useConnectionStore } from '@/stores/ConnectionStore'
import { useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/GlobalStore'
import warningIcon from '@/assets/images/warning-small.svg'

const connectionStore = useConnectionStore()
const globalStore = useGlobalStore()

const router = useRouter()

const handleRedirectToExportHistory = (connection = 'ebay') => {
  connection === 'ebay' ? router.replace(`/connections/${connection}/history`) : router.replace(`/connections/${connection}/export-history`)
  connectionStore.showExportToast = false
}

const handleTryAgain = () => {
  connectionStore.listedErrorModal = true
  connectionStore.showExportToast = false
}
</script>

<template>
  <Transition name="fade">
    <section
      class="fixed bottom-4 left-20 shadow rounded-lg p-4 bg-white w-[372px] text-sm z-[1099]"
      v-if="connectionStore.showExportToast"
    >
      <section class="flex items-start gap-x-3">
        <!-- We show channel logo when export succeed -->
        <img v-if="!globalStore.errors && connectionStore.exportedChannelInfo?.miniLogo" :src="connectionStore.exportedChannelInfo.miniLogo" class="w-8 h-8 object-contain" />
        <img v-if="globalStore.errors" :src="warningIcon" class="w-8 h-8 object-contain" />
        <!-- Here's the failed icon -->
        <!-- <span class="p-1.5 rounded-lg bg-red-100 flex-none">
          <Icon icon="heroicons:exclamation-circle" class="text-xl text-red-600" />
        </span> -->
        <section class="flex-1">
          <header class="flex items-start justify-between mb-1 flex-1">
            <h3 class="font-semibold">{{ globalStore.errors ? `Failed to export to ${connectionStore.exportedChannelInfo?.name}` : ['collx', 'ebay-direct'].includes(connectionStore.exportedChannelInfo?.key) ? connectionStore.exportedChannelInfo?.label || `Now Listing on ${connectionStore.exportedChannelInfo?.name}` : 'Export succeeded' }}</h3>
            <!-- <h3 class="font-semibold">Failed to export to CollX</h3> -->
            <button @click="connectionStore.showExportToast = false">
              <Icon icon="heroicons:x-mark" class="text-base" />
            </button>
          </header>
          <template v-if="!globalStore.errors">
            <p class="text-gray-600 text-xs mb-3" v-if="connectionStore.exportedChannelInfo?.successful_cards || connectionStore.exportedChannelInfo?.countOfCards"> 
              {{ `${connectionStore.exportedChannelInfo.successful_cards || connectionStore.exportedChannelInfo.countOfCards} ${['collx', 'ebay-direct'].includes(connectionStore.exportedChannelInfo?.key) ? connectionStore.exportedChannelInfo?.message || 'items are being listed on CollX. This process may take a couple minutes.' : `Listings has been exported to ${connectionStore.exportedChannelInfo.name}`}` }} 
            </p>
            <p v-if="connectionStore.exportedChannelInfo.failed_cards" class="text-gray-600 text-xs mb-3"> 
              {{ `${connectionStore.exportedChannelInfo.failed_cards} cards could not be listed on CollX.` }}
            </p>
          </template>
          <p v-else class="text-gray-600 text-xs mb-3"> An unexpected error occurred </p>
          <!-- <p class="text-gray-600 text-xs mb-3"> There’s an server outage </p> -->

          <button v-if="!globalStore.errors" class="font-semibold text-tint-1 transition hover:text-tint-2" @click="() => handleRedirectToExportHistory(connectionStore.exportedChannelInfo?.key)">
            View in Export History
          </button>
          <button v-else class="font-semibold text-tint-1 transition hover:text-tint-2" @click="handleTryAgain">
            Try Again
          </button>
          <!-- <button v-else class="font-semibold text-tint-1 transition hover:text-tint-2" @click="handleViewDetails">
            View Details
          </button> -->
        </section>
      </section>
    </section>
  </Transition>
</template>
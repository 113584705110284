import moment from 'moment'
import noCard from '@/assets/images/no-card.svg'
import { NO_CARD_IMAGE_URL } from '@/constants'

const generateCardData = (card = {}, selectedRanges, config) => {
    const noImages = !card.front_image && !card.back_image
  
    let front_image = card.front_image ? `${card.front_image}?_t=${new Date().getTime()}` : noImages && card.match_front_image ? `${card.match_front_image}?_t=${new Date().getTime()}` : NO_CARD_IMAGE_URL
    let back_image = card.back_image ? `${card.back_image}?_t=${new Date().getTime()}` : noImages && card.match_back_image ? `${card.match_back_image}?_t=${new Date().getTime()}` : null
  
    let front_image_sm = card.front_image_sm ? `${card.front_image_sm}?_t=${new Date().getTime()}fr_sm` : noImages && card.match_front_image ? `${card.match_front_image}?_t=${new Date().getTime()}fr_sm` : NO_CARD_IMAGE_URL
    let back_image_sm = card.back_image_sm ? `${card.back_image_sm}?_t=${new Date().getTime()}bg_sm` : noImages && card.match_back_image ? `${card.match_back_image}?_t=${new Date().getTime()}bg_sm` : null
  
    let front_image_md = card.front_image_md ? `${card.front_image_md}?_t=${new Date().getTime()}fr_md` : noImages && card.match_front_image ? `${card.match_front_image}?_t=${new Date().getTime()}fr_md` : NO_CARD_IMAGE_URL
    let back_image_md = card.back_image_md ? `${card.back_image_md}?_t=${new Date().getTime()}bg_md` : noImages && card.match_back_image ? `${card.match_back_image}?_t=${new Date().getTime()}bg_md` : null
  
    // XXX TODO we assume everything that is not processing or ready_to_list is draft
    // We should added proper handling for 'failed', and eventually 'listed' and 'sold'
    let status = 'draft'
    if (['processing', 'ready_to_list'].includes(card.status)) {
      status = card.status
    }
  
    let matches = []
    let bestMatch = null
  
    // XXX TODO
    if (card.matches) {
      let matchData = null
  
      if (typeof card.matches === 'string') {
        try {
          matchData = JSON.parse(card.matches)
        } catch (e) {
          console.error('error parsing `matches`', card, e)
        }
      } else if (typeof card.matches === 'object') {
        matchData = card.matches
      }
  
      let haveLegacyMatches = matchData.legacyMatches && matchData.legacyMatches?.length
      
      let haveVisionMatches = matchData.vision && matchData.vision?.length
  
      // XXX why is this an array? isn't there only one selected match?
      let haveSelectedMatches = matchData.selected && Object.keys(matchData.selected).length
  
      if (haveLegacyMatches && matchData.legacyMatches[0].frontImageUrl) {
        haveLegacyMatches = false
        haveVisionMatches = true
        matches = matchData.legacyMatches
      } else if (haveLegacyMatches) {
        matches = matchData.legacyMatches
      } else if (haveVisionMatches) {
        matches = matchData.vision  // XXX This should be the only scenario in production
      }
  
      // User has selected a different match, use that match
      if (card.collx_match_id && haveSelectedMatches) {
        matches = [matchData.selected] // XXX
        
        bestMatch = {
          id: matches[0].id,
          front_image: card.frontImageUrl === '' ? noCard : matches[0].frontImageUrl,
          back_image: matches[0].backImageUrl,
          title: matches[0].set?.name + ' #' + matches[0]?.number + ' ' + (matches[0]?.name || matches[0].player?.name) + (matches[0].serialNumber ? ` #/${matches[0].serialNumber}` : ''), // XXX
          card_number: matches[0].number,
          player: matches[0]?.name || matches[0].player?.name,
          year: matches[0].year,
          set: matches[0].set?.name, // XXX CollX set names include subset if not parents
          setId: card.collx_parent_set_id || matches[0].set?.id, // XXX
          brand: matches[0].brand?.name,
          subset: matches[0].set?.subset,
          attributes: matches[0].flags?.join(', '),
          variation: matches[0].variation,
          serialNumber: matches[0].serialNumber,
        }
      }
  
      // User has not selected a match, but we have a vision match, use the first vision match
      if (card.collx_match_id && !haveSelectedMatches && haveVisionMatches) {
        matches = card.matches.vision
        
        bestMatch = {
          id: matches[0].id,
          front_image: card.frontImageUrl === '' ? noCard : card.match_front_image,
          back_image: card.backImageUrl === '' ? noCard : card.match_back_image,
          title: matches[0].set?.name + ' #' + matches[0]?.number + ' ' + (matches[0]?.name || matches[0].player?.name) + (matches[0].serialNumber ? ` #/${matches[0].serialNumber}` : ''), // XXX
          card_number: matches[0].number,
          player: matches[0].player?.name,
          year: matches[0].year,
          set: matches[0].set?.name,
          setId: card.collx_parent_set_id || matches[0].set?.id,
          brand: matches[0].brand?.name,
          subset: matches[0].set?.subset,
          attributes: matches[0].flags?.join(', '),
          variation: matches[0].variation,
          serialNumber: matches[0].serialNumber,
        }
      }
    }
  
    // XXX what is this? why are we doing it this way? why not use the actual field names?
    const generateCustomFields = (fields) => fields.reduce((acc, item, index) => {
      acc[`custom_fields_${index}`] = item[item.value ? 'value' : 'default_value']
      return acc
    }, {})
  
    let customFieldsList = []
    let customFields = {}
    if ((!card.custom_fields || !card.custom_fields?.length) && config.customFields && config.customFields.length) {
      customFieldsList = config.customFields
      customFields = generateCustomFields(config.customFields)
    } else if ((!card.custom_fields || !card.custom_fields?.length) && (!config.customFields || !config.customFields.length)) {
      customFields = null
      customFieldsList = null
    } else if ((card.custom_fields && card.custom_fields?.length) && (!config.customFields || !config.customFields.length)) {
      customFields = generateCustomFields(card.custom_fields)
      customFieldsList = card.custom_fields
    } else {
      const fields = config.customFields.reduce((acc, item) => {
        const customFieldInCard = card.custom_fields.find(i => i.id === item.id)
  
        if (customFieldInCard) {
          acc.push(customFieldInCard)
        } else {
          acc.push(item)
        }
  
        return acc
      }, [])
  
      customFields = generateCustomFields(fields)
      customFieldsList = fields
    }
      
    let cardData = {
      id: card.id,
      viewed: card.viewed,
      is_loading: card.status === 'processing',
      has_errors: card.has_errors || (card.errors && card.errors?.length > 0),
      errors: card.errors,
      has_banner: card.has_banner,
      has_suggested_edits: card.has_suggested_edits || (card.suggested_edits && card.suggested_edits?.length > 0),
      suggested_edits: card.suggested_edits,
      green: !card.has_errors && (card.errors && !card.errors.length > 0) && !card.has_suggested_edits && (card.suggested_edits && !card.suggested_edits.length),
      yellow: (!card.has_errors && (card.errors && !card.errors.length > 0)) && (card.has_suggested_edits || (card.suggested_edits && card.suggested_edits.length > 0)),
      red: card.has_errors || (card.errors && card.errors.length > 0),
      select: selectedRanges ? !!selectedRanges.find(item => item.id === card.id) : false,
      front_image: front_image,
      back_image: back_image,
      front_image_sm: front_image_sm,
      back_image_sm: back_image_sm,
      front_image_md: front_image_md,
      back_image_md: back_image_md,
      title: card.title,
      template_title: card.title_template,
      template_title_id: card.title_template_id,
      template_description_id: card.description_template_id,
      database_match: bestMatch || {},
      matches: matches,
      status: status,
      start_price: card.start_price,
      collx_match_id: card.collx_match_id,
      sport: card.sport ? card.sport === 'NON_SPORT' ? 'Non-Sport' : card.sport : null,
      year: card.year,
      brand: card.brand,
      player: card.player,
      card_number: card.number,
      subset: card.subset,
      attributes: card.flags,
      team: card.team,
      set: card.set,
      variation: card.variation,
      graded: card.graded,
      grader: card.grader,
      condition: card.condition,
      cert_no: card.certification_number,
      grade_name: card.grade_name,
      grade_number: card.grade_number,
      purchase_price: card.purchase_price,
      purchase_date:  card.purchase_date ? moment(card.purchase_date).format('YYYY-MM-DD') : card.purchase_date,
      note: card.note,
      inventory_location: 'FIXME',
      cabinet: card.cabinet,
      shelf: card.shelf,
      box: card.box,
      row: card.row,
      section: card.section,
      sku: card.sku,
      description: card.description,
      description_plain_text: card.description  && String(card.description).replace(/<[^>]+>/gi, " "),
      template_description_plain_text: card.description_template && String(card.description_template)?.replace(/<style[\s\S]*?<\/style>/gi, '')?.replace(/<[^>]+>/gi, " "),
      template_description: card.description_template,
      type: card.type,
      customFieldsListForInspector: customFieldsList,
      frontOrientation: card.front_orientation,
      backOrientation: card.back_orientation,
      serial_number: card.serial_number,
      cert_data: card.cert_data,
      collx_listed_at: card.collx_listed_at,
      ebay_listed_at: card.ebay_listed_at,
      whatnot_listed_at: card.whatnot_listed_at,
      ebay_offer_id: card.ebay_offer_id,
      ...customFields
    }
  
    return cardData  
  }

  const isValidCert = (card, selectedGrader) => {
    if (['cgc', 'sgc', 'bgs', 'bvg', 'bccg'].includes(selectedGrader)) return true

    if (['psa'].includes(selectedGrader)) {
      return !!(Number(card)) && (card.length === 8 || card.length === 9)
    }
  }

  const swapForMobile = (card) => {
    const card_ = { ...card }

    const frontImage = card_.front
    const backImage = card_.back
    const thumbnailFront = card_.thumbnailFront
    const thumbnailBack = card_.thumbnailBack

    return { ...card_, front: backImage, back: frontImage, thumbnailFront: thumbnailBack, thumbnailBack: thumbnailFront }
  }

  export {
    generateCardData,
    isValidCert,
    swapForMobile
  }
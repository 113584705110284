import { icons } from '@/helpers/icons.js'
import psaGrader from '@/assets/images/graders/psa.svg'
import sgcGrader from '@/assets/images/graders/sgc.svg'
import beckettGrader from '@/assets/images/graders/bgc.svg'
import cgcGrader from '@/assets/images/graders/cgc.svg'
import bvgGrader from '@/assets/images/graders/bvg.svg'
import bccgGrader from '@/assets/images/graders/bccg.svg'

export const DEFAULT_CDP_TEMPLATE = { 
  content: '',
  default: true,
  id: null,
  name: 'Default CDP Template'
}

export const BATCH_CARD_COLUMN_OPTIONS = [
  { checked: true, field: 'title', name: 'Title', eBay: true, shopify: true },
  { checked: true, field: 'database_match', name: 'Database Match', eBay: true, shopify: true },
  { checked: true, field: 'status', name: 'Status', eBay: true, shopify: true },
  { checked: true, field: 'listings', name: 'Listings', eBay: true, shopify: true },
  {
    checked: true,
    field: 'start_price',
    name: 'Sale Price',
    eBay: true,
    shopify: true,
  },
  { checked: true, field: 'sport', name: 'Category', eBay: true, shopify: true },
  { checked: true, field: 'year', name: 'Year', eBay: true, shopify: true },
  { checked: true, field: 'brand', name: 'Brand', eBay: true, shopify: true },
  { checked: true, field: 'set', name: 'Set', eBay: true, shopify: true },
  { checked: true, field: 'player', name: 'Name', eBay: true, shopify: true },
  {
    checked: true,
    field: 'card_number',
    name: 'Card No.',
    eBay: true,
    shopify: true,
  },
  {
    checked: true,
    field: 'subset',
    name: 'Subset/Parallel',
    eBay: true,
    shopify: true,
  },
  {
    checked: true,
    field: 'attributes',
    name: 'Attributes',
    eBay: true,
    shopify: true,
  },
  { checked: true, field: 'team', name: 'Team', eBay: true, shopify: true },
  {
    checked: true,
    field: 'variation',
    name: 'Variation',
    eBay: true,
    shopify: true,
  },
  { checked: true, field: 'graded', name: 'Graded', eBay: true, shopify: true },
  { checked: true, field: 'grader', name: 'Grader', eBay: true, shopify: true },
  {
    checked: true,
    field: 'condition',
    name: 'Condition',
    eBay: true,
    shopify: true,
  },
  {
    checked: true,
    field: 'cert_no',
    name: 'Cert No. ',
    eBay: true,
    shopify: true,
  },
  {
    checked: true,
    field: 'grade_number',
    name: 'Grade',
    eBay: true,
    shopify: true,
  },
  {
    checked: true,
    field: 'purchase_date',
    name: 'Purchase Date',
    eBay: false,
    shopify: false,
  },
  {
    checked: true,
    field: 'purchase_price',
    name: 'Purchase Price',
    eBay: false,
    shopify: false,
  },
  { checked: true, field: 'note', name: 'Note', eBay: false, shopify: false },
  {
    checked: true,
    field: 'cabinet',
    name: 'Cabinet',
    eBay: false,
    shopify: false,
  },
  { checked: true, field: 'shelf', name: 'Shelf', eBay: false, shopify: false },
  { checked: true, field: 'box', name: 'Box', eBay: false, shopify: false },
  { checked: true, field: 'row', name: 'Row', eBay: false, shopify: false },
  {
    checked: true,
    field: 'section',
    name: 'Section',
    eBay: false,
    shopify: false,
  },
  { checked: true, field: 'sku', name: 'SKU', eBay: true, shopify: true },
  {
    checked: true,
    field: 'description',
    name: 'Description',
    eBay: true,
    shopify: true,
  },
]

export const SPORTS = [
  { id: 'Baseball', name: 'Baseball' },
  { id: 'Basketball', name: 'Basketball' },
  { id: 'Football', name: 'Football' },
  { id: 'Hockey', name: 'Hockey' },
]

export const GRADED = [
  { id: 0, name: 'No' },
  { id: 1, name: 'Yes' },
]

export const CONDITION = [
  { id: 'Near Mint or Better', name: 'Near Mint or Better' },
  { id: 'Excellent', name: 'Excellent' },
  { id: 'Very Good', name: 'Very Good' },
  { id: 'Poor', name: 'Poor' },
]

export const TCG_CONDITION = [
  { id: 'Near Mint or Better', name: 'Near Mint or Better' },
  { id: 'Lightly played', name: 'Lightly played' },
  { id: 'Moderately played', name: 'Moderately played' },
  { id: 'Heavily played', name: 'Heavily played' },
]

export const NON_SPORT_CONDITION = [
  { id: 'Near Mint or Better', name: 'Near Mint or Better' },
  { id: 'Excellent', name: 'Excellent' },
  { id: 'Very good', name: 'Very good' },
  { id: 'Poor', name: 'Poor' },
]

export const ALL_CONDITIONS = {
  sports: CONDITION,
  tcg: TCG_CONDITION,
  nonsport: NON_SPORT_CONDITION
}

export const CARD_TYPES = {
  sports: 'Sports',
  tcg: 'TCG',
}

export const CARD_TYPES_FULL_NAME = {
  sports: 'Sport Trading Card',
  tcg: 'Trading Cards Games',
  nonsport: 'Non-Sport Cards'
}

export const BATCH_TYPE_LABEL = {
  sports: 'Sport',
  tcg: 'Game',
  nonsport: 'Non-Sport'
}

export const ASSORTED = {
  sports: 'Assorted Sports',
  tcg: 'Assorted Games',
  nonsport: 'Assorted Non-sports'
}

export const FIELD_OPTION = {
  graded: GRADED,
  condition: CONDITION,
}

export const BATCH_DETAILS_INFO_TABS = [
  { name: 'Pinned Fields', key: 'pinned'},
  { name: 'Card Attributes', key: 'details'},
  { name: 'Grading & Condition', key: 'condition'},
  { name: 'Pricing & Inventory', key: 'pricing'},
  { name: 'Custom Fields', key: 'custom'},
  { name: 'Title & Description', key: 'listing'},
  { name: 'Listings', key: 'listings'},
  { name: 'Notes', key: 'notes'}
]

export const VIEW_OPTIONS = [
  { label: 'All fields', value: '' },
  { label: 'eBay', value: 'eBay' },
  { label: 'Shopify', value: 'shopify' },
]

export const BATCH_DETAILS_VIEW_OPTIONS = [
  { icon: icons.bullet_list, value: 'Overview' },
  { icon: icons.inspector, value: 'Inspector' },
]

export const SORT_OPTIONS = [
  'Recently added',
  'Oldest to newest',
  'Sale price high to low',
  'Sale price low to high',
]

export const DISCLOSURES = {
  processingBatchCards: { header: { title: 'Processing' }, dataKey: 'processingBatchCards' },
  draftBatchCards: { header: { title: 'Draft' }, dataKey: 'draftBatchCards' },
  readyToListBatchCards: { header: { icon: 'heroicons:check-circle', title: 'Ready to List' }, dataKey: 'readyToListBatchCards' },
}

export const BULK_FIELDS_INFO = [
  { field: 'title_template_id', name: 'Title' },
  { field: 'status', name: 'Status' },
  { field: 'start_price', name: 'Sale Price' },
  { field: 'sport', name: 'Category' },
  { field: 'year', name: 'Year' },
  { field: 'brand', name: 'Brand'},
  { field: 'set', name: 'Set'},
  { field: 'subset', name: 'Subset / Parallel'},
  { field: 'player', name: 'Name' },
  { field: 'card_number', name: 'Card Number' },
  { field: 'attributes', name: 'Attributes' },
  { field: 'team', name: 'Team' },
  { field: 'variation', name: 'Variation' },
  { field: 'graded', name: 'Graded' },
  { field: 'grader', name: 'Grader' },
  { field: 'condition', name: 'Condition' },
  { field: 'cert_no', name: 'Certification Number' },
  { field: 'grade_number', name: 'Grade' },
  { field: 'purchase_price', name: 'Purchase Price' },
  { field: 'purchase_date', name: 'Purchase Date' },
  { field: 'sku', name: 'SKU' },
  { field: 'note', name: 'Note' },
  { field: 'cabinet', name: 'Cabinet' },
  { field: 'shelf', name: 'Shelf' },
  { field: 'box', name: 'Box' },
  { field: 'row', name: 'Row' },
  { field: 'section', name: 'Section' },
  { field: 'description_template_id', name: 'Description' },
]

export const BULK_FIELDS_OBJECT = {
  front_image: { field: 'front_image', name: 'Front image' },
  title: { field: 'title', name: 'Title' },
  type: { field: 'type', name: 'Type' },
  sport:{ field: 'sport', name: 'Category' },
  status: { field: 'status', name: 'Status' },
  start_price: { field: 'start_price', name: 'Sale Price' },
  brand: { field: 'brand', name: 'Brand'},
  set: { field: 'set', name: 'Set'},
  subset: { field: 'subset', name: 'Subset / Parallel'},
  year: { field: 'year', name: 'Year' },
  card_number: { field: 'card_number', name: 'Card Number' },
  number: { field: 'card_number', name: 'Card Number' },
  player: { field: 'player', name: 'Name' },
  variation: { field: 'variation', name: 'Variation' },
  attributes: { field: 'attributes', name: 'Attributes' },
  team: { field: 'team', name: 'Team' },
  condition: { field: 'condition', name: 'Condition' },
  grade_number: { field: 'grade_number', name: 'Grade' },
  cert_no: { field: 'cert_no', name: 'Certification Number' },
  graded: { field: 'graded', name: 'Graded' },
  grader: { field: 'grader', name: 'Grader' },
  purchase_price: { field: 'purchase_price', name: 'Purchase Price' },
  purchase_price: { field: 'purchase_date', name: 'Purchase Date' },
  sku: { field: 'sku', name: 'SKU' },
  note: { field: 'note', name: 'Note' },
  cabinet: { field: 'cabinet', name: 'Cabinet' },
  shelf: { field: 'shelf', name: 'Shelf' },
  box: { field: 'box', name: 'Box' },
  row: { field: 'row', name: 'Row' },
  sku: { field: 'sku', name: 'SKU' },
  section: { field: 'section', name: 'Section' },
  description: { field: 'description', name: 'Description' },
  title_template_id: { field: 'title_template_id', name: 'Title template' },
  description_template_id: { field: 'description_template_id', name: 'Description template' },
  title_template: { field: 'title_template', name: 'Title template' },
}

export const BULK_FIELDS = {
  title: '',
  status: '',
  start_price: '',
  sport: '',
  year: '',
  brand: '',
  set: '',
  subset: '',
  player: '',
  card: '',
  attributes: '',
  team: '',
  variation: '',
  graded: '',
  grader: '',
  condition: '',
  cert: '',
  grade: '',
  purchase_price: '',
  purchase_date: '',
  note: '',
  cabinet: '',
  shelf: '',
  box: '',
  row: '',
  section: '',
  sku: '',
  description: '',
  title_template_id: '',
  description_template_id: ''
}

export const BATCHES_SORT_OPTIONS = [
  {
    label: 'Recently added',
    value: 'createdAt.desc',

  },
  {
    label: 'Last modified',
    value: 'updatedAt.asc'
  },
  {
    label: 'Oldest to newest',
    value: 'createdAt.asc'
  },
  {
    label: 'A-Z',
    value: 'name.asc'
  },
  {
    label: 'Z-A',
    value: 'name.desc'
  },
]

export const BATCH_DETAILS_SORT_OPTIONS = [
  {
    label: 'Upload filename',
    value: 'filename',
  },
  {
    label: 'Card Number',
    value: 'number',
  },
  {
    label: 'Recently added',
    value: 'createdAt.desc',
  },
  {
    label: 'Oldest to newest',
    value: 'createdAt.asc'
  },
  {
    label: 'Last modified',
    value: 'updatedAt.desc'
  },
  {
    label: 'Status A-Z',
    value: 'status.asc'
  },
  {
    label: 'Status Z-A',
    value: 'status.desc'
  },
  {
    label: 'Sale price high to low',
    value: 'salePrice.desc'
  },
  {
    label: 'Sale price low to high',
    value: 'salePrice.asc'
  },
  {
    label: 'Purchase price high to low',
    value: 'purchasePrice.desc'
  },
  {
    label: 'Purchase price low to high',
    value: 'purchasePrice.asc'
  },
  {
    label: 'Name A-Z',
    value: 'player.asc'
  },
  {
    label: 'Name Z-A',
    value: 'player.desc'
  },
  {
    label: 'Team name A-Z',
    value: 'team.asc'
  },
  {
    label: 'Team name Z-A',
    value: 'team.desc'
  },
]

export const ORDER_FIELDS_FOR_TAB_EVENT_INSPECTOR = [
  'type', 'sports', 'category', 'year', 'brand', 'set', 'card_number', 'player', 'subset', 'variation', 'attributes', 'team',
  'graded', 'grader', 'grade_number', 'cert_no', 'condition', 
  'purchase_price', 'purchase_date', 'start_price', 'sku', 'cabinet', 'shelf', 'box', 'row', 'section'
]

export const EXPORT_BUTTONS = [
  { name: 'all', label: 'All Cards' },
  { name: 'ready_to_list', label: 'Ready To List Cards' },
]

export const NO_CARD_IMAGE_URL = "https://storage.googleapis.com/collx-media/no-card-image.png"

export const GRADING_SERVICE = [
  { name: 'psa', logo: psaGrader },
  { name: 'cgc', logo: cgcGrader },
  { name: 'sgc', logo: sgcGrader },
  { name: 'bgs', logo: beckettGrader },
  { name: 'bvg', logo: bvgGrader },
  { name: 'bccg', logo: bccgGrader },
]

export const GRADING_SERVICE_LOGO = {
  psa: psaGrader,
  cgc: cgcGrader,
  sgc: sgcGrader,
  bgs: beckettGrader,
  bvg: bvgGrader,
  bccg: bccgGrader,
}

export const REQUIRED_FIELDS_FOR_LIST_TO_COLLX = ['category', 'year', 'player', 'set', 'start_price']

export const BATCH_DETAIL_UNREVIEWED_FILTER = { icon: 'heroicons:eye', name: 'Unreviewed', key_name: 'viewed' }

export const BATCH_DETAIL_FILTERS = {
  status_tabs: {
    selected: 'All',
    options: ['All', 'Draft', 'Ready to List']
  },

  fast_actions: [
    { icon: 'heroicons:no-symbol', name: 'Missing Details', key_name: 'with_errors' },
    { icon: 'heroicons:exclamation-circle', name: 'Suggested Edits', key_name: 'with_suggested_edits' },
  ],

  filter_blocks: [
    [
      { icon: { is_local: true, value: icons.status_spinner }, name: 'Status', key_name: 'status' },
      // { icon: { is_local: true, value: icons.text_T }, name: 'Content' }
    ],
    [
      { ...BATCH_DETAIL_UNREVIEWED_FILTER },
      { icon: 'heroicons:no-symbol', name: 'Missing Details', key_name: 'with_errors' },
      { icon: 'heroicons:exclamation-circle', name: 'Suggested Edits', key_name: 'with_suggested_edits' },
    ],
    [
      { icon: { is_local: true, value: icons.icon_multisport }, name: 'Categories', field: 'categories', key_name: 'categories' },
      { icon: 'heroicons:tag', name: 'Brand', field: 'brand', key_name: 'brand' },
      { icon: 'heroicons:information-circle', name: 'Attribute', field: 'attributes', key_name: 'attribute' },
      { icon: 'heroicons:scale-20-solid', name: 'Graded', field: 'graded', key_name: 'graded' },
      { icon: 'heroicons:scale-20-solid', name: 'Grader', field: 'grader', key_name: 'grader' },
      // { icon: 'heroicons:currency-dollar', name: 'Price' },
      // { icon: 'heroicons:map-pin', name: 'Location' },
    ],
  ]
}

export const FILTER_TYPE_CONTROL_ENUM = {
  Combobox: 'Combobox',
  SearchBar: 'SearchBar',
  ValueSliderRange: 'ValueSliderRange',
}

export const VIEW_GRADER_WEBSITE_LOOKUP_LINKS = {
  PSA: 'https://www.psacard.com/cert/',
  CGC: 'https://www.cgccards.com/certlookup/'
}

export const SECTION_INDEXES_RELATIVE_TO_THE_ROUTE_INSPECTOR = {
  'details': 0,
  'condition': 1, 
  'pricing': 2,
  'custom': 3,
  'listing': 4,
  'listings': 5,
  'notes': 6,
}

export const SECTION_INDEXES_RELATIVE_TO_THE_ROUTE_INSPECTOR_PINNED = {
  'pinned': 0,
  'details': 1,
  'condition': 2, 
  'pricing': 3,
  'custom': 4,
  'listing': 5,
  'listings': 6,
  'notes': 7,
}

export const SCAN_WAYS = [
  {
    state: 'magicScan',
    icon: 'magic-scan.png',
    title: 'Magic Scan',
    description: 'Bulk upload card images for identification with Card Vision AI'
  },
  {
    state: 'searchDatabase',
    icon: 'search-database.png',
    title: 'Search Database',
    description: 'Manually search within our database of 15 million cards'
  },
  {
    state: 'certImport',
    icon: 'search-barcode.png',
    title: 'Search Cert Numbers',
    description: 'Import graded card using their certification number'
  },
  {
    state: 'mobileScan',
    icon: 'mobile-scan-v1.png',
    title: 'Upload from Phone',
    description: 'Capture photos or upload images from your phone'
  },
]

export const UPLOAD_IMAGES_SIDES_STEPS = [
  { text: 'Add Card Options', name: 'types', checked: false },
  { text: 'Choose Sides', name: 'sides', checked: false },
  { text: 'Upload Images', name: 'upload', checked: false },
]

export const UPLOAD_IMAGES_SCAN_STEPS = [
  { text: 'Add Card Options', name: 'types', checked: false },
  { text: 'Upload Images', name: 'scan', checked: false },
]
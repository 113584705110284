import { defineStore, acceptHMRUpdate } from 'pinia'
import { apiClient } from "@/helpers/api.js"

export const useEbayLocationsStore = defineStore('EbayLocationsStore', {
  state: () => {
    return {
      locationsLoader: false,
      locations: [],
      selectedLocation: null,
      openLocationDialog: false,
      addingLocation: false,
      merchantLocationKey: null
    }
  },

  getters: {},

  actions: {
    async fetchLocations() {
      try {
        this.locationsLoader = true

        const { data } = await apiClient().get(`/ebay/locations`)

        if (data?.ok) {
          this.locations = data.locations
        }

        this.locationsLoader = false
      } catch (error) {
        this.locationsLoader = false
        console.error('fetchLocations error', error)
      }
    },

    async updateLocation(merchantLocationKey) {
      try {
        const { data } = await apiClient().patch(`/ebay/locations/${merchantLocationKey}`, this.selectedLocation)

        if (data?.ok) {
          this.locations = this.locations.reduce((acc, item) => {
            if (item.merchantLocationKey === merchantLocationKey) {
              acc.push(data.data)
            } else {
              acc.push(item)
            }

            return acc
          }, [])
        }
      } catch (error) {
        console.error('updateLocation error', error)
      }
    },

    async deleteLocations(merchantLocationKey) {
      try {
        const { data } = await apiClient().delete(`/ebay/locations/${merchantLocationKey}`)

        if (data?.ok) {
          this.locations = this.locations.filter(item => item.merchantLocationKey !== merchantLocationKey)
        }
      } catch (error) {
        console.error('deleteLocations error', error)
      }
    },

    async createEbayLocation() {
      try {
        const { data } = await apiClient().post(`/ebay/locations`, this.selectedLocation)

        if (data?.ok) {
          this.locations = [...this.locations, data.data]
        }
      } catch (error) {
        console.error('createEbayLocation error', error)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEbayLocationsStore, import.meta.hot))
}
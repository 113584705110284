<script setup>
import { onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { initFlowbite } from 'flowbite'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useBatchStore } from '@/stores/BatchStore'
import { useUserStore } from './stores/UserStore'
import { useKeyboardShortcut } from '@/hooks/useKeyboardShortcut'
import { usePusher } from '@/hooks/usePusher'
import { useCello } from '@/hooks/useCello'
import MainLayout from '@/components/MainLayout/index.vue'
import GlobalVideoWidget from '@/components/GlobalVideoWidget.vue'
import NewVersionToast from '@/components/NewVersionToast.vue'
import ListingsExportToast from '@/components/ListingsExportToast.vue'
import * as Sentry from "@sentry/browser"

const route = useRoute()
const router = useRouter()

const userStore = useUserStore()
const globalStore = useGlobalStore()
const batchStore = useBatchStore()

const isAuthLayout = computed(() => !route.meta?.requireAuth)
const user = computed(() => userStore.user)

useKeyboardShortcut(router)
usePusher(userStore)
useCello()

globalStore.fetchBuildTxt()

setInterval(() => {
  globalStore.fetchBuildTxt()
}, 300000)

onMounted(async () => {
  globalStore.windowWidth = window.innerWidth
  globalStore.windowHeight = window.innerHeight
  window.addEventListener('resize', () => {
    globalStore.windowWidth = window.innerWidth
    globalStore.windowHeight = window.innerHeight

    if (window.innerWidth < 1280) {
      globalStore.sidebar = false
    }
  })

  // initialize components based on data attribute selectors
  initFlowbite();

  const params = new URLSearchParams(window.location.search)
  const authAs = params.get('authAs')
  const sessionOverrideAuthToken = sessionStorage.getItem('cdp_auth_override_token')

  if (authAs || sessionOverrideAuthToken) {
    await userStore.checkSession(authAs || sessionOverrideAuthToken)
    console.log('logged in as', userStore.user?.email)
    router.replace('/')
  } else {
    userStore.checkSession()
  }

  if (navigator.userAgent.search('Mac') !== -1) {
    globalStore.userAgent = 'cmd'
  } else {
    globalStore.userAgent = 'ctrl'
  }
  globalStore.sidebar =  JSON.parse(localStorage.getItem('sidebar') || false)
  localStorage.setItem('sidebar', JSON.stringify(globalStore.sidebar))
})

watch(user, () => {
  userStore.user && batchStore.fetchConfigs({ withoutLoader: true })
  userStore.user && Sentry.setUser(userStore.user)
})

watch(route, () => {
  if (route.path === '/batches') {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        zIndex: 1,
        offset: { horizontal: '75px', vertical: '55px' },
      }
    })

    zE('webWidget', 'show')
  } else if(route.path.includes('history')) {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: { horizontal: '5px', vertical: '50px' }
      }
    })
  }
   else if (['MobileScan', 'MobileScanSides', 'MobileScanUpload', 'MobileScanCompleted'].includes(route.name)) {
    zE('webWidget', 'hide')
  }
   else {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: { horizontal: '5px', vertical: '5px' }
      }
    })

    zE('webWidget', 'show')
  }
})

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  if (message.includes('ResizeObserver loop completed with undelivered notifications')) {
    console.error('ResizeObserver loop error detected:', {
      message,
      source,
      lineno,
      colno,
      error
    });
  }
  // Return false to prevent the default handling of the error
  return false;
};

// Event listener for global errors
window.addEventListener('error', function (event) {
  if (event.message.includes('ResizeObserver loop completed with undelivered notifications')) {
    console.error('ResizeObserver loop error detected:', {
      message: event.message,
      source: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: event.error
    });
  }
});

// Event listener for unhandled promise rejections
window.addEventListener('unhandledrejection', function (event) {
  if (event.reason && event.reason.message && event.reason.message.includes('ResizeObserver loop completed with undelivered notifications')) {
    console.error('ResizeObserver loop error detected (unhandled rejection):', event.reason);
  }
});
</script>

<template>
  <!-- <RouterView v-if="isAuthLayout || batchStore.config?.subscription?.plan_id" /> -->
  <NewVersionToast />
  <ListingsExportToast />

  <RouterView v-if="isAuthLayout || ['MobileScan', 'MobileScanSides', 'MobileScanUpload', 'MobileScanCompleted'].includes(route.name)" />
  <template v-else>
    <MainLayout />

    <GlobalVideoWidget />
  </template>
</template>

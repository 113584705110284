import Pusher from 'pusher-js'
import { onMounted, onUnmounted, watch } from 'vue'
import { useBatchStore } from '@/stores/BatchStore'
import { useConnectionStore } from '@/stores/ConnectionStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useRoute, useRouter } from 'vue-router'
import { PUSHER_EVENTS_TO_CHANNELS } from '@/constants'
import { isMobile } from '@/helpers/general'

export function usePusher(userStore) {
  const batchStore = useBatchStore()
  const connectionStore = useConnectionStore()
  const globalStore = useGlobalStore()

  const route = useRoute()
  const router = useRouter()

  let pusher = null
  let channel = null

  const initializePusher = () => {
    Pusher.logToConsole = true

    pusher = new Pusher('f1107eef8ca40861ee35', {
      cluster: 'mt1'
    })

    channel = pusher.subscribe(`channel-${userStore.user?.id}`)

    channel.bind('batchUpdate', handleBatchUpdate)
    channel.bind('collx_import_completed', (data, event) => handleImportCompleted(data, event, 'collx_import_completed'))
    channel.bind('ebay_import_completed', (data, event) => handleImportCompleted(data, event, 'ebay_import_completed'))
    // channel.bind('ebay_import_failed', (data, event) => handleImportCompleted(data, event, 'ebay_import_failed'))
    channel.bind('ebay_import_failed', (data, event) => console.log('>>>--', data, event, 'ebay_import_failed'))
    channel.bind('otp_login_completed', handleOtpLoginCompleted)
    channel.bind('selected_image_side', handleSelectedImageSide)
    channel.bind('otp_login_ended', handleOtpLoginEnded)
    channel.bind('collx_auth_failed', handleCollxAuthFailed)
  }

  const handleBatchUpdate = (data) => {
    if (
      data?.Event === 'card_processing_completed' ||
      data?.Event === 'imort_cert_processing_completed' ||
      data?.Event === 'imort_cert_processing_failed' ||
      data?.Event === 'card_processing_failed' ||
      data?.Event === 'rotate_processing_completed'
    ) {
      batchStore.updateCard(data.CardId, data?.Event)
    }

    if (data?.Event === 'replace_card_processing_completed') {
      batchStore.updateCard(data.CardId, data?.Event)
      setTimeout(() => {
        batchStore.replaceImageLoader = false
      }, 0)
    }

    if (
      (data?.Event === 'remove_banner_completed' || data?.Event === 'add_banner_completed') &&
      data.LastCard
    ) {
      batchStore.fetchSelectedCards(null, false)
    }

    if (data?.Event === 'swap_processing_completed') {
      batchStore.updateCard(data.CardId, data?.Event)

      if (data.LastCard) {
        batchStore.updateSwappedToast(true, 'Image swap complete!')
      }
    }

    if (data?.Event === 'card_reprocessing_completed' && data.LastCard) {
      batchStore.fetchSelectedCards(null, false)
    }

    if (data?.Event === 'scan_counts_updated') {
      batchStore.config = {
        ...batchStore.config,
        availableScans: data.AvailableScans,
        totalScans: data.TotalScans
      }
    }

    if (data?.Event === 'card_uploaded_to_cloud_storage' && data?.MobileUpload) {
      const filesNames = data?.UploadFilenames && JSON.parse(data?.UploadFilenames)
      batchStore.uploads = [
        ...batchStore.uploads,
        {
          front: { name: filesNames.frontFilename || '' },
          back: { name: filesNames.backFilename || '' },
          thumbnail: null,
          thumbnailFront: data.Images.FrontSmImage || null,
          thumbnailBack: data.Images.BackSmImage || null,
          progress: 0,
          pending: false,
          uploaded: true,
          cardId: data.CardId
        }
      ]
      batchStore.countOfUploadedImagesFromMobile =
        batchStore.countOfUploadedImagesFromMobile + 1
    }
  }

  const handleImportCompleted = (data, event, typeOfEvent) => {
    connectionStore.showExportToast = true
    
    if (typeOfEvent === 'ebay_import_failed') {
      globalStore.errors = true
    }

    connectionStore.exportedChannelInfo = {
      ...PUSHER_EVENTS_TO_CHANNELS[typeOfEvent].channel,
      label: 'Listings published',
      message: `cards have been listed on ${PUSHER_EVENTS_TO_CHANNELS[typeOfEvent].name}.`,
      key: 'ebay-direct',
      ...data
    }
    
    if (route.params.batchId) {
      batchStore.fetchSelectedCards(null, false)
    }
  }

  const handleOtpLoginCompleted = () => {
    if (!isMobile()) {
      router.push(`/batches/${route.params.batchId}/add/upload`)
      userStore.otpLoginDone = true
    }
  }

  const handleSelectedImageSide = (data) => {
    if (!isMobile()) {
      router.replace(`${route.path}?sides=${data.side}`)
    }
  }

  const handleOtpLoginEnded = () => {
    if (isMobile()) {
      router.push(`/mobile-scan/session-ended`)
    }
  }

  const handleCollxAuthFailed = () => {
    connectionStore.collxAuthFailedFunc()
  
    router.push(`/connections/collx/connect/account-connection`)
  }

  watch(() => userStore.loggedIn, () => {
    if (userStore.loggedIn) {
      initializePusher()
    }
  })

  onUnmounted(() => {
    if (channel) {
      channel.unbind_all()
      channel.unsubscribe()
    }
    if (pusher) {
      pusher.disconnect()
    }
  })
}

import { defineStore, acceptHMRUpdate } from 'pinia'

import { apiClient } from "@/helpers/api.js"
import { generateCardData } from "@/helpers/card.js"
import { sortSubsetSuggestions } from "@/helpers/batch.js"
import { setToLocalStorageByUserId } from '@/helpers/general'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useUserStore } from '@/stores/UserStore'
import { useSearchStore } from '@/stores/SearchStore'
import { useCreateBatchStore } from "@/stores/CreateBatchStore";
import {
  BATCH_CARD_COLUMN_OPTIONS,
  GRADED,
  SPORTS_AND_GAMES,
  VIEW_OPTIONS,
  BATCH_DETAILS_VIEW_OPTIONS,
  DISCLOSURES,
  FIELD_OPTION,
  BULK_FIELDS_OBJECT,
  CONDITION,
  TCG_CONDITION,
  DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS,
  ALL_CONDITIONS,
  BATCH_TYPE_LABEL
} from '@/constants'
import { useQRCode } from '@vueuse/integrations/useQRCode'

export const useBatchStore = defineStore('BatchStore', {
  state: () => {
    return {
      apiBaseUrl: import.meta.env.VITE_API_HOST,
      batchProcessorBaseUrl: import.meta.env.VITE_BATCH_PROCESSOR_API_HOST,
      gridApi: null,

      batches: [],
      pagination: null,

      indexLoading: true,
      batchLoading: false,
      loadedById: {},

      batchKind: '',
      selectedBatch: null,
      selectedBatchId: null,
      selectedBatchName: null,
      batchItems: [],
      uploads: [],
      countOfUploadedImagesFromMobile: 0,
      itemFilters: {},

      notificationStatus: {},
      pinnedCardData: {},
      pinnedFields: [
        {field: 'database_match'},
        {field: 'title_id'},
        {field: 'start_price'},
        {field: 'subset'},
        {field: 'player'},
        {field: 'set'},
      ],
      selectedCardId: null,
      selectedCards: {},
      selectedRanges: null,
      fieldSuggestions: [],
      fieldSearchResult: [],
      columnOptions: [...BATCH_CARD_COLUMN_OPTIONS],
      graded: [...GRADED],
      grader: [],

      viewOptions: [...VIEW_OPTIONS],
      viewOption: { ...VIEW_OPTIONS[0] },
      selectedBatchDetailsViewOption: BATCH_DETAILS_VIEW_OPTIONS[0].value,

      disclosures: { ...DISCLOSURES },

      selectedColumn: '',
      shouldUpdateColumn: false,
      isBulkEditing: false,
      isAllFilterItem: false,

      openedPanel: false,

      config: {},
      loadingConfig: true,
      loadingConfigInBackground: false,

      settings: {
        name: '',
        ...DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS
      },

      batchType: 'sports',

      recentlyUploadedImagesIds: [],

      batchImageCellsLoading: false,

      swappedToast: false,
      swappedToastMessage: '',

      currentBulkValue: {},
      loadingSuggestions: false,

      reopenBulkEditingInfo: {
        open: false,
      },

      automaticallyGenerateSku: true,

      deleteCardSuccess: false,
      deleteCardError: false,

      inspectorExpandedErrorBlock: false,

      rowNode: null,

      qrcodeLink: `${import.meta.env.VITE_MAIN_SITE}/mobile-scan`,
      otpPassword: '',
      generateOtpPasswordLoader: false,
    }
  },

  getters: {
    condition() {
      return ALL_CONDITIONS[this.selectedBatch?.type]
    },
    selectedViewOption() {
      const userStore = useUserStore()
      const savedBatchDetailsViewOption = JSON.parse(localStorage.getItem('batchDetailsViewOption'))

      return (savedBatchDetailsViewOption && savedBatchDetailsViewOption[userStore.user?.id]) || this.viewOption
    },
    batchCardsBasedOnStatus() {
      const draftBatchCards = []
      const readyToListBatchCards = []
      const processingBatchCards = []

      for(const item of this.batchItems) {
        item.status === 'draft' && draftBatchCards.push(item)
        item.status === 'ready_to_list' && readyToListBatchCards.push(item)
        item.status === 'processing' && processingBatchCards.push(item)
      }
      return {
        draftBatchCards,
        readyToListBatchCards,
        processingBatchCards
      }
    },
    selectedCardData() {
      return this.batchItems.find(item => item.id === Number(this.selectedCardId)) || {}
    },
    sport() {
      let type = this.selectedBatch?.type || 'sports'

      if (type === 'tcg') {
        type = 'games'
      }

      return this.config ? this.config[type] : []
    },
    grade_number() {
      let grade = []

      if (!this.selectedCardData) return grade

      if (Object.keys(this.selectedCardData).length) {
        const grader = this.config.graders?.find(item => item.name === this.selectedCardData?.grader)

        if (!grader) return grade

        grade = grader.grading_scale.reduce((acc, item) => {
          acc.push({ name: item, id: item })

          return acc
        }, [])
      }

      return grade
    },
    attributes() {
      return this.selectedCardData.attributes ? this.selectedCardData.attributes.split(',') : []
    },
    cardErrors() {
      const attributes = []
      const grading = []
      const pricing = []
      const templates = []
      const images = []

      if (Object.keys(this.selectedCardData).length) {
        for (const [key, value] of Object.entries(this.selectedCardData?.errors)) {
          if (['front_image'].includes(key) && this.selectedBatch.type === 'sports') {
            images.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key].field}-el` })
          }
          if (['year', 'type', 'sport', 'brand', 'set', 'card_number', 'player', 'subset', 'variation', 'attributes', 'team', 'number'].includes(key) && this.selectedBatch.type === 'sports') {
            attributes.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key].field}-el`, desc: '' })
          }
          if (['type', 'sport', 'brand', 'set', 'card_number', 'player', 'variation', 'attributes', 'number'].includes(key) && this.selectedBatch.type === 'tcg') {
            attributes.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key].field}-el`, desc: '' })
          }
          if (['condition', 'grade', 'grader', 'cert_no', 'grade_number'].includes(key)) {
            grading.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key].field}-el`, desc: '' })
          }
          if (['start_price', 'purchase_price', 'sku', 'cabinet', 'shelf', 'box', 'row', 'section'].includes(key)) {
            pricing.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key].field}-el`, desc: '' })
          }
          if (['title', 'description', 'title_template'].includes(key)) {
            const key_ = key === 'title_template' ? 'title' : key
            templates.push({ text: BULK_FIELDS_OBJECT[key].name, el: `inspector-${BULK_FIELDS_OBJECT[key_].field}-el`, desc: this.selectedCardData.errors[BULK_FIELDS_OBJECT[key_].field] })
          }
        }
      }

      return {
        attributes,
        grading,
        pricing,
        templates,
        images
      }
    },

    currentPlan() {
      if (!this.config.plans) return {}
      return this.config.plans.find(plan => {
        return this.config.subscription.plan_id === plan.id
      })
    },

    currentPlanIndex() {
      if (!this.config.plans) return null
      return this.config.plans.findIndex(plan => {
        return this.config.subscription.plan_id === plan.id
      })
    },

    topPlan() {
      if (!this.config?.plans) return null

      return this.config.plans[this.config.plans.length - 1]
    },

    applicablePinnedFieldsCount() {
      if(this.selectedBatch?.type !== 'tcg'){
        return this.pinnedFields.length
      }
      else{
        const fieldsToExclude = ['year', 'team', 'subset']
        let counter = this.pinnedFields.length
        for (const pinnedField of this.pinnedFields) {
          if (fieldsToExclude.includes(pinnedField.field)) {
            counter--
          }
        }
        return counter
      }
    }
  },

  actions: {
    addToPinned(field) {
      if (!this.pinnedFields.some(item => item.field === field)) {
        if (field === 'database_match') {
          this.pinnedFields.unshift({ field })
        } else {
          this.pinnedFields.push({ field })
        }

        this.updateLocalStoragePinnedFields()
      }
    },
    removeFromPinned(field) {
      const index = this.pinnedFields.findIndex(item => item.field === field)
      if (index !== -1) {
        this.pinnedFields.splice(index, 1)
        this.updateLocalStoragePinnedFields()
      }
    },

    updateLocalStoragePinnedFields() {
      const userStore = useUserStore()

      setToLocalStorageByUserId(this.pinnedFields, 'pinnedCardFields', userStore.user.id)
    },

    checkLocalStoragePinnedFields(){
      const userStore = useUserStore()
      let localStoragePinnedFields = localStorage.getItem('pinnedCardFields')

      if (localStoragePinnedFields) {
        try {
          const parsedPinnedFields = localStoragePinnedFields && JSON.parse(localStoragePinnedFields)[userStore.user.id] ? JSON.parse(localStoragePinnedFields)[userStore.user.id] : []
          this.pinnedFields = parsedPinnedFields
        } catch (error) {
          console.error('Error parsing pinnedCardFields from localStorage:', error)
        }
      } else{
        setToLocalStorageByUserId(this.pinnedFields, 'pinnedCardFields', userStore.user.id)
      }
    },

    updateSwappedToast(value, message = '') {
      this.swappedToast = value
      this.swappedToastMessage = message

      setTimeout(() => {
        this.swappedToast = false
        this.swappedToastMessage = ''
      }, 4000);
    },
    updateViewOptions(availableViewOptions) {
      if (availableViewOptions.length) {
        this.viewOptions = VIEW_OPTIONS.reduce((acc, item) => {
          if (availableViewOptions.includes(item.value)) {
            acc.push(item)
          }
          return acc
        }, [])

        this.viewOptions = [VIEW_OPTIONS[0], ...this.viewOptions]
      } else {
        this.viewOptions = []
      }
    },

    updatedSelectedCard(id) {
      this.selectedCardId = id
    },

    updateStateField(stateKey, value) {
      this[stateKey] = value
    },

    updateColumnOption(option) {
      const userStore = useUserStore()

      this.columnOptions = this.columnOptions.reduce((acc, item) => {
        item.field === option.field ? acc.push({...item, checked: !item.checked}) :  acc.push(item)

        return acc
      }, [])

      const displayColumns = JSON.parse(localStorage.getItem("display_columns")) || {}
      localStorage.setItem("display_columns", JSON.stringify({ ...displayColumns, [userStore.user.id]: this.columnOptions }))
    },

    updateSelectedBatch(data, config) {
      const configuration = this.config || config

      if (data) {
        this.selectedBatch = data
        this.selectedBatchId = data.id
      }

      if (data && configuration && Object.keys(configuration).length) {
        const sportsAndGamesBasedOnType = data.type === 'tcg'
          ? configuration.games.find(item => item.collx_type_id === data.collx_type_id)
          : configuration[data.type].find(item => item.collx_type_id === data.collx_type_id)

        const titleTemplateKey = Object.keys(configuration.titleTemplates).find(key => configuration.titleTemplates[key].id === data.title_template_id)
        const descriptionTemplateKey = Object.keys(configuration.descriptionTemplates).find(key => configuration.descriptionTemplates[key].id === data.description_template_id)
        this.settings = {
          name: data.name,
          titleTemplates: configuration.titleTemplates[titleTemplateKey] ||  { id: null, name: 'Default CDP Template' },
          descriptionTemplates: configuration.descriptionTemplates[descriptionTemplateKey] || { id: null, name: 'Default CDP Template' },
          sportsAndGamesBasedOnType: sportsAndGamesBasedOnType || { collx_type_id: null, name: data.type === 'tcg' ? 'Assorted Games' : 'Assorted Sports' },
          batchTypes: { name: BATCH_TYPE_LABEL[data.type], id: data.type },
        }

        this.batchType = data.type
      }
    },

    updateToDefault() {
      const createBatchStore = useCreateBatchStore()

      this.viewOptions = VIEW_OPTIONS
      this.columnOptions = BATCH_CARD_COLUMN_OPTIONS
      this.selectedBatchDetailsViewOption = BATCH_DETAILS_VIEW_OPTIONS[0].value
      this.selectedBatch = null
      this.selectedBatchId = null
      this.selectedCardId = null
      this.settings = {
        name: '',
        ...DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS
      }
      this.pinnedCardData = {}
      this.itemFilters = {}
      this.selectedRanges = null
      this.selectedCards = {}

      createBatchStore.batchType = 'sports'
    },

    async fetchBatches({ page = 1, status = null, query = 'createdAt.desc' }) {
      this.indexLoading = true

      let url = `/batches?page=${page}&sortBy=${query}`
      if (status) {
        url += `&status=${status}`
      }

      try {
        const response = await apiClient().get(url)

        const data = response.data

        this.batches = data.items

        this.pagination = {
          perPage: 25,
          currentPage: data.current_page,
          totalItems: data.total_items,
          nextPage: data.next_page ? data.current_page + 1 : null,
          prevPage: data.current_page === 1 ? null : data.current_page - 1,
          totalPages: data.total_pages
        }

        return data.items
      } catch (e) {
        console.log('fetchBatches error', e)
      } finally {
        this.indexLoading = false
      }
    },

    fetchPrevPage() {
      if (this.pagination?.prevPage) {
        this.fetchBatches(this.pagination?.prevPage)
      }
    },
    fetchNextPage() {
      if (this.pagination?.nextPage) {
        this.fetchBatches(this.pagination?.nextPage)
      }
    },

    async fetchBatch(batchId) {
      const userStore = useUserStore()
      try {
        const { data } = await apiClient().get(`/batches/${batchId}`)

        console.log('fetchBatch data', data)
        this.updateSelectedBatch(data)

        const displayCol = localStorage.getItem("display_columns") ? JSON.parse(localStorage.getItem("display_columns"))[userStore.user.id] ? [ ...JSON.parse(localStorage.getItem("display_columns"))[userStore.user.id]] : [...BATCH_CARD_COLUMN_OPTIONS] : [...BATCH_CARD_COLUMN_OPTIONS]

        this.columnOptions = displayCol
      } catch (e) {
        console.error('fetchBatch error', e)
      }
    },

    async fetchSelectedCards(query, turnOnLoader = true) {
      const userStore = useUserStore()
      const globalStore = useGlobalStore()

      if (turnOnLoader) {
        this.batchLoading = true
      }

      console.log('fetchSelectedCards')
      let _query = { ...query }

      if (!_query.sortBy) {
        const savedBatchDetailsSort = JSON.parse(localStorage.getItem('batchDetailsSort'))

        _query = { ..._query, sortBy: savedBatchDetailsSort && savedBatchDetailsSort[userStore.user?.id] && savedBatchDetailsSort[userStore.user?.id].value || 'filename' }
      }

      await this.fetchConfigs()

      this.selectedBatchName = null

      try {
        const response = await apiClient().get(`/batches/${this.selectedBatchId}/cards`, _query ? {
          params: _query
        } : {})

        const data = response.data

        this.selectedBatchName = data.batch_name

        const batchItems = []
        data.items.forEach((card, index) => {
          const cardData = generateCardData(card, this.selectedRanges, this.config)
          batchItems.push(cardData)
        })

        this.batchItems = batchItems

        globalStore.cellsLoading = false
        this.batchImageCellsLoading = false
      } catch (error) {
        console.error('fetchSelectedCards error', error)
      } finally {
        this.batchLoading = false
        globalStore.cellsLoading = false
        this.batchImageCellsLoading = false
      }
    },

    async fetchAdditionalSuggestions(card_id, field, query) {
      const results = [
        'Option One',
        'Option Two',
        'Option Three',
        'Option Four',
        'Option Five',
      ]

      try {
        // const { data } = await apiClient().get('/batch/detail/', {
        //   params: {
        //     batch_id: this.selectedBatchId,
        //     card_id
        //     field,
        //     query
        //   }
        // })

        this.fieldSearchResult = results.filter((result) => {
          return  result
                  .toLowerCase()
                  .replace(/\s+/g, '')
                  .includes(query.toLowerCase().replace(/\s+/g, ''))
        })
      } catch (e) {
        console.log('fetchAdditionalSuggestions error', e)
      }
    },

    async updateFields(cardId, body) {
      try {
        if (!this.config?.hasActiveSubscription) return

        const { data } = await apiClient().patch(`/cards/${cardId}`, body)

        const card = this.batchItems.find(element => element.id === cardId)

        const getFocusedCell = window.gridApi?.getFocusedCell()
        const getRowNode = getFocusedCell ? window.gridApi?.getDisplayedRowAtIndex(getFocusedCell.rowIndex) : null
        getRowNode && window.gridApi && window.gridApi.refreshCells({
          rowNodes: [getRowNode],
          force: true,
        })

        const newCardData = generateCardData(data, this.selectedRanges, this.config)

        const updatedProperties = {}
        const commonKeys = Object.keys(newCardData).filter((key) => card.hasOwnProperty(key))
        commonKeys.forEach((key) => {
          updatedProperties[key] = newCardData[key]
        });

        Object.assign(card, updatedProperties);
      } catch (e) {
        console.log('updateFields error', e)
      }
    },

    async updateField(value, cardId, field) {
      try {
        if (!this.config?.hasActiveSubscription) return

        let backendFieldName = field
        if (field === 'attributes') {
          backendFieldName = 'flags'
        }

        const dataForUpdate = {
          [backendFieldName]: value
        }

        if (['type', 'sport', 'year', 'brand', 'set', 'player', 'subset', 'team', 'variation', 'card_number', 'number'].includes(field)) {
          dataForUpdate['collx_match_id'] = null
          dataForUpdate['serial_number'] = null
        }

        const { data, response } = await apiClient().patch(`/cards/${cardId}`, dataForUpdate)

        const card = this.batchItems.find(element => element.id === cardId)

        const getFocusedCell = window.gridApi?.getFocusedCell()
        const getRowNode = getFocusedCell ? window.gridApi?.getDisplayedRowAtIndex(getFocusedCell.rowIndex) : null

        const newCardData = generateCardData(data, this.selectedRanges, this.config)
        field !== 'subset' && window.gridApi && window.gridApi.applyTransaction({ update: [newCardData] })

        const updatedProperties = {}
        const commonKeys = Object.keys(newCardData).filter((key) => card.hasOwnProperty(key))
        commonKeys.forEach((key) => {
          updatedProperties[key] = newCardData[key]
        })

        getRowNode && window.gridApi && window.gridApi.refreshCells({
          rowNodes: [getRowNode],
          columns: [field, 'title'],
          force: true,
          suppressFlash: true
        })

        Object.assign(card, updatedProperties)
      } catch (e) {
        console.log('updateField error', e)
      }
    },

    async updateFieldsBatch({ value, field, unlinkPromptActionTrigger = '', additionalAction = ''}) {
      if (!this.config?.hasActiveSubscription) return

      const arrayOfIds = []
      let selectedRange = this.selectedRanges ? [ ...this.selectedRanges ] : []

      try {
        let backendFieldName = field || this.selectedColumn
        if (this.selectedColumn === 'attributes') {
          backendFieldName = 'flags'
        }

        if (this.selectedColumn === 'card_number') {
          backendFieldName = 'number'
        }

        if (this.selectedColumn === 'cert_no') {
          backendFieldName = 'certification_number'
        }

        if (this.selectedColumn && this.shouldUpdateColumn) {
          // for column
          selectedRange = this.batchItems.map((item, index) => {
            return {
              startIndex: index
            }
          })
        }

        for (let i = 0; i < selectedRange.length; i++) {
          if (this.selectedColumn === 'status' && this.batchItems[selectedRange[i].startIndex].red && value === 'ready_to_list') continue
          if (['grader', 'cert_no', 'grade_number'].includes(this.selectedColumn) && !this.batchItems[selectedRange[i].startIndex].graded) continue
          if (['condition'].includes(this.selectedColumn) && this.batchItems[selectedRange[i].startIndex].graded) continue

          if (backendFieldName === 'collx_match_id') {
            if (this.batchItems[selectedRange[i].startIndex].subset) {
              arrayOfIds.push(this.batchItems[selectedRange[i].startIndex].id)
            }
          } else if (backendFieldName === 'subset' && unlinkPromptActionTrigger === 'remove') {
            if (this.batchItems[selectedRange[i].startIndex].subset) {
              arrayOfIds.push(this.batchItems[selectedRange[i].startIndex].id)
            }
          } else {
            arrayOfIds.push(this.batchItems[selectedRange[i].startIndex].id)
          }
        }

        if (this.selectedColumn === 'sku' && this.automaticallyGenerateSku && !additionalAction) {
          await apiClient().post('/cards/re-sku', {
            cardIds: arrayOfIds
          })
        } else {
          await apiClient().post('/cards/bulk-update', {
            cardIds: arrayOfIds,
            field: backendFieldName,
            value
          })
        }

        this.fetchSelectedCards()
      } catch (error) {
        console.log('updateFieldsBatch error', error)
      }
    },

    async updateCard(cardId, triggerEvent) {
      if (!this.config?.hasActiveSubscription) return

      const globalStore = useGlobalStore()
      try {
        const card = this.batchItems.find(item => item.id === cardId)

        if (!card) {
          console.log('cardId', cardId, 'not found in current batch')
          return
        }

        const { data } = await apiClient().get(`/cards/${cardId}`)

        if (!data) return

        const newCardData = generateCardData(data, this.selectedRanges, this.config)

        console.log('updating cardId', cardId)
        Object.assign(card, newCardData)
        globalStore.pusherLoading = false
      } catch (e) {
        console.error('updateCard error', e)
        globalStore.pusherLoading = false
      }
    },

    async updateCardStatus(cardId, status) {
      if (!this.config?.hasActiveSubscription) return

      console.log('updateCardStatus cardId', cardId, 'status', status)
      try {
        await this.updateField(status, cardId, 'status')
      } catch (e) {
        console.log('updateCardStatus error', e)
      }
    },

    async updateBatch(body) {
      try {
        if (!this.config?.hasActiveSubscription) return

        const { data } = await apiClient().put(`/batches/${this.selectedBatchId}`, body)

        this.batches = this.batches.reduce((acc, element) => {
          if (element.id === data.id) {
            acc.push({ ...element, ...body })
          } else {
            acc.push(element)
          }

          return acc
        }, [])

        if (this.selectedBatch) {
          this.updateSelectedBatch({ ...data })
        }
      } catch (error) {
        console.log('updateBatch error', error)
      }
    },

    toggleBatchStatus(batch) {
      this.selectedBatchId = batch.id
      this.updateBatch({ status: batch.status === 'open' ? 'closed' : 'open' })
    },

    async rotateImage(imageTab, degreeOfRotation = 90) {
      const globalStore = useGlobalStore()
      const splittedTab = imageTab.split('_')
      const tab = splittedTab[0]

      try {
        globalStore.pusherLoading = true
        const { data, response } = await apiClient(true).post(`/cards/${this.selectedCardId}/rotate-${tab}/${degreeOfRotation}`)

        if (response && !response.data.ok) {
          globalStore.pusherLoading = false
        }
      } catch (error) {
        console.log('rotateImage error', error)
        globalStore.pusherLoading = false
      }
    },

    async deleteCards(ids) {
      try {
        let cardIds = []

        if (!ids) {
          for (let i = 0; i < this.selectedRanges.length; i++) {
            cardIds.push(this.selectedRanges[i]?.id)
          }
        } else {
          cardIds = ids
        }

        const { data } = await apiClient().delete('/cards', {
          data: {
            cardIds
          }
        })

        this.selectedRanges = []
        this.selectedCards = {}

        this.batchItems = this.batchItems.reduce((acc, item) => {
          if (!data.cardIds.includes(item.id)) {
            acc.push(item)
          }

          return acc
        }, [])
      } catch (error) {
        console.log('deleteCards error', error)
      }
    },

    async deleteCard() {
      try {
        const { data } = await apiClient().delete(`/cards/${this.selectedCardData.id}`)

        if (data?.deleted) {
          this.deleteCardSuccess = true
        } else {
          this.deleteCardError = true
        }
      } catch (error) {
        console.log('deleteCard error', error)
        this.deleteCardError = true
      }
    },

    async swapImages() {
      const cardIds = []

      try {
        if (this.selectedRanges) {
          for (let i = 0; i < this.selectedRanges.length; i++) {
            cardIds.push(this.batchItems[this.selectedRanges[i].startIndex].id)
          }
        } else {
          cardIds.push(this.selectedCardId)
        }


        apiClient(true).post('/cards/swap-images', {
          cardIds
        })

      } catch (error) {
        console.log('swapImages error', error)
      }
    },

    async replaceImage(formData) {
      const globalStore = useGlobalStore()

      try {
        globalStore.replaceImageLoader = true
        apiClient(true).put(`/cards/${this.selectedCardId}?skip_vision=1`, formData)
      } catch (error) {
        console.log('replaceImage error', error)
        globalStore.replaceImageLoader = false
      }
    },

    async fetchConfigs(options) {
      if (this.loadingConfigInBackground) return

      try {
        if (!options?.withoutLoader) {
          this.loadingConfig = true
        }

        this.loadingConfigInBackground = true

        const { data } = await apiClient().get('/config')

        this.config = data

        this.loadingConfig = false
        this.loadingConfigInBackground = false

        this.grader = data.graders
        this.selectedBatch && this.updateSelectedBatch(this.selectedBatch, data)
      } catch (error) {
        this.loadingConfig = false
        this.loadingConfigInBackground = false
        console.log('fetchConfigs error', error)
      }
    },

    async reprocessBatch() {
      if (!this.config?.hasActiveSubscription) return
      const userStore = useUserStore()
      const globalStore = useGlobalStore()

      try {
        globalStore.cellsLoading = true

        if (this.selectedBatchDetailsViewOption !== 'Overview') {
          this.batchLoading = true
        }

        const data = await apiClient(true).post(`/users/${userStore.user.id}/batches/${this.selectedBatchId}/reprocess`)
      } catch (error) {
        console.log('reprocessBatch error', error)
      }
    },

    async addBannerToBatch() {
      if (!this.config?.hasActiveSubscription) return
      const userStore = useUserStore()

      try {
        await apiClient(true).post(`/users/${userStore.user.id}/batches/${this.selectedBatchId}/add-banner?banner=${userStore.user.banner_image}&location=${userStore.user?.banner_placement || 'top'}`)
      } catch (error) {
        console.error('addBannerToBatch error', error)
      }
    },

    async removeBannerFromBatch() {
      if (!this.config?.hasActiveSubscription) return
      const userStore = useUserStore()

      try {
        await apiClient(true).post(`/users/${userStore.user.id}/batches/${this.selectedBatchId}/remove-banner?banner=${userStore.user.banner_image}`)
      } catch (error) {
        console.error('removeBannerFromBatch error', error)
      }
    },

    async updateMatchedCard(selectedMatch) {
      try {
        if (!this.config?.hasActiveSubscription) return

        const { data } = await apiClient().post(`/cards/${this.selectedCardId}/update-match`, { ...selectedMatch })

        if (!data) return

        const card = this.batchItems.find(item => item.id === data.id)

        if (!card) {
          console.log('updateMatchedCard card id', data.id, 'not found in current batch')
          return
        }

        const newCardData = generateCardData(data, this.selectedRanges, this.config)
        window.gridApi && window.gridApi.applyTransaction({ update: [newCardData] })

        console.log('updateMatchedCard updating card id', data.id)

        Object.assign(card, newCardData)

        // No need for whole other separate request!
        // this.updateCard(data.id)
      } catch (error) {
        console.log('updateMatchedCard error', error)
      }
    },

    async importMatchedCard(selectedMatch) {
      try {
        if (!this.config?.hasActiveSubscription) return

        const { data } = await apiClient().post(`/batches/${this.selectedBatchId}/import-match`, { ...selectedMatch, sport: selectedMatch.sport === 'NON_SPORT' ? 'Non-Sport' : selectedMatch.sport })
        this.fetchSelectedCards()

        return data
      } catch (error) {
        console.log('updateMatchedCard error', error)
      }
    },

    async removeBatch() {
      try {
        await apiClient().delete(`/batches/${this.selectedBatchId}`)
        this.batches = this.batches.reduce((acc, item) => {
          if (item.id !== this.selectedBatchId) {
            acc.push(item)
          }

          return acc
        }, [])
      } catch (error) {
        console.log('removeBatch error', error)
      }
    },

    async updateCustomFieldByCardId(body, fieldIndex) {
      try {
        if (!this.config?.hasActiveSubscription) return

        const { data } = await apiClient().put(`/cards/${this.selectedCardId}/custom-field`, body)
        this.selectedCardData[`custom_fields_${fieldIndex}`] = body.value

        this.updateCard(this.selectedCardId)

        this.selectedCardData.customFieldsListForInspector = this.selectedCardData.customFieldsListForInspector.reduce((acc, item) => {
          if (item.id === body.id) {
            acc.push({ ...item, value: body.value })
          } else {
            acc.push(item)
          }

          return acc


        }, [])
      } catch (error) {
        console.log('updateCustomFieldByCardId error', error)
      }
    },

    async fetchCardsStatuses() {
      try {
        if (!this.batchCardsBasedOnStatus.processingBatchCards.length) return

        const { data } = await apiClient().get(`batches/${this.selectedBatchId}/cards?status=processing`)

        for(let i = 0; i < this.batchCardsBasedOnStatus.processingBatchCards.length; i++) {
          const isStillProcessing =  data ? data.items.find(item => item.id === this.batchCardsBasedOnStatus.processingBatchCards[i].id) : false
          if (!isStillProcessing) {
            await this.updateCard(this.batchCardsBasedOnStatus.processingBatchCards[i].id)
          }
        }

      } catch (error) {
        console.log('fetchCardsStatuses error', error)
      }
    },

    async fetchSubset(parentId, collxMatchId) {
      try {
        if (this.selectedBatchDetailsViewOption !== 'Inspector' && !this.openedPanel) {
          this.fieldSuggestions = []
        }

        this.loadingSuggestions = true
        const { data } = await apiClient().get(`suggestions/subsets?parent_set_id=${parentId}&collx_match_id=${collxMatchId}`)

        const baseCard = []
        const cards = []

        if (data.matches.length === 1 && !data.matches[0].set.parent && !data.matches[0].set.subset) {
          this.fieldSuggestions = []
          this.loadingSuggestions = false
          return
        }

        for(let i = 0; i < data.matches.length; i++) {
          if (!data.matches[i].set.parent) {
            baseCard.push(data.matches[i])
          } else {
            cards.push({ ...data.matches[i] })
          }
        }

        this.fieldSuggestions = [...baseCard.sort(sortSubsetSuggestions), ...cards.sort(sortSubsetSuggestions)]
        this.loadingSuggestions = false
      } catch (error) {
        this.loadingSuggestions = false
        console.log('fetchSubset error', error)
      }
    },

    async getOtpPassword(batchId) {
      try {
        this.generateOtpPasswordLoader = true
        const { data } = await apiClient().post(`otp-links`, {
          redirect_to: `/mobile-scan/${batchId}`
        })

        if (data?.ok) {
          const splitLoginUrl = data.otp_login_url.split('/')
          const url = `${import.meta.env.VITE_MAIN_SITE}/otp-links/${splitLoginUrl[splitLoginUrl.length - 1]}`

          this.qrcodeLink = useQRCode(url)
        }

        this.generateOtpPasswordLoader = false
      } catch (error) {
        this.generateOtpPasswordLoader = false
        console.log('getOtpPassword error', error)
      }
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBatchStore, import.meta.hot))
}

import { defineStore } from 'pinia'

import { apiClient } from "@/helpers/api.js"
import { useUserStore } from '@/stores/UserStore'
import { useBatchStore } from '@/stores/BatchStore'
import { 
  FORM_OPTIONS, 
  BATCH_TYPES, 
  DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS, 
  DEFAULT_BATCH_INFO, 
  ASSORTED,
  BATCH_TYPE_LABEL
} from '@/constants'

export const useCreateBatchStore = defineStore('CreateBatchStore', {
  state: () => {
    return {
      formOptions: { ...FORM_OPTIONS },
      batchType: 'sports', 
      batchTypes: [ ...BATCH_TYPES ],
      titleTemplates: [],
      descriptionTemplates: [],
      batchInfo: { ...DEFAULT_BATCH_INFO },
    }
  },
  getters: {
    preparedFormOptions() {
      const batchStore = useBatchStore()
      this.batchType = batchStore.selectedBatch?.type || this.batchType

      return Object.entries(this.formOptions).reduce((acc, [k, item]) => {     
        if (item.field === 'collx_type_id' && this.batchType && batchStore.config) {
          const configSports = batchStore.config[this.batchType === 'tcg' ? 'games' : this.batchType]
          const collxType = configSports && configSports.length ? [...configSports] : []
          item.options = collxType || []
          const hasAssortedObject = item.options?.find(item => !item.collx_type_id)
          !hasAssortedObject && this.batchType !== 'nonsport' && item.options.unshift({ name: ASSORTED[this.batchType], collx_type_id: null })
          item.label = BATCH_TYPE_LABEL[this.batchType]
        
          item.selected = { name: this.batchType === 'nonsport' ? batchStore.config[this.batchType] ? batchStore.config[this.batchType][0].name : '' : ASSORTED[this.batchType], collx_type_id: this.batchType === 'nonsport' ? batchStore.config[this.batchType] ? batchStore.config[this.batchType][0].collx_type_id : null : null }
        } else if (item.field === 'type') {
          item.options = BATCH_TYPES
        } else if (item.field === 'title_template_id' || item.field === 'description_template_id') {
          item.options = batchStore.config[item.key]

          if (item.options) {
            item.options = [{ id: null, name: 'Default CDP Template' }, ...item.options]
          }

          const defaultTemplate = batchStore.config[item.key]?.find(item => item.default)

          if (defaultTemplate) {
            item.selected = defaultTemplate
          }
        } else {
          item.options = batchStore.config[item.key]
        }

        acc.push(item)
        return acc
      }, [])
    }
  },
  actions: {
    updateField(field, value) {
      if (field === 'type') {
        this.batchType = value
      }
      
      this.batchInfo = { ...this.batchInfo, [field]: value }
    },

    clearCreatingBatchInfo() {
      this.formOptions.batchTypes.selected = DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS.batchTypes
      this.formOptions.sportsAndGamesBasedOnType.selected = DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS.sportsAndGamesBasedOnType
      this.formOptions.titleTemplates.selected = DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS.titleTemplates
      this.formOptions.descriptionTemplates.selected = DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS.descriptionTemplates
      this.batchInfo = { ...DEFAULT_BATCH_INFO }
      this.titleTemplates = []
      this.descriptionTemplates = []
    },

    async fetchTitleAndDescriptionTemplates() {
      const descriptionTemplates = []
      const titleTemplates = []

      try {
        const { data } = await apiClient().get(`/templates`)

        data.forEach((template) => {
          if (template.type === 'title') {
              titleTemplates.push(template)
          } else if (template.type === 'description') {
              descriptionTemplates.push(template)
          }
        })

        this.titleTemplates = [ { id: null, name: 'Default CDP Template' }, ...titleTemplates ] 
        this.descriptionTemplates = [ { id: null, name: 'Default CDP Template' }, ...descriptionTemplates ]
      } catch (error) {
        console.error('fetchTitleAndDescriptionTemplates error', error)
      }
    },

    async createBatch() {
      const userStore = useUserStore()
      const batchStore = useBatchStore()

      if (!batchStore.config?.hasActiveSubscription) return

      try {
        const {data} = await apiClient().post(`/batches`, {
          name: this.batchInfo.name,
          collx_type_id: this.formOptions.sportsAndGamesBasedOnType.selected.collx_type_id,
          condition: this.batchInfo.condition.name,
          description_template_id: this.formOptions.descriptionTemplates.selected.id || null,
          title_template_id: this.formOptions.titleTemplates.selected.id || null,
          type: this.batchType,
          // XXX FIXME not supported by backend
          purchase_price: this.batchInfo.purchase_price ? parseFloat(this.batchInfo.purchase_price) : null,
          purchase_date: this.batchInfo.purchase_date,
          start_price: this.batchInfo.start_price ? parseFloat(this.batchInfo.start_price) : null,
          cabinet: this.batchInfo.cabinet,
          shelf: this.batchInfo.shelf,
          box: this.batchInfo.box,
          row: this.batchInfo.row,
          section: this.batchInfo.section,
          description: this.batchInfo.description,
          user_id: userStore.user.id, // XXX this should really be pulled from authentication
          new_sku: true
        })

        if (!data) {
          throw new Error('data not found', data)
        }

        return data ? data.id : null
      } catch (error) {
        console.error('createBatch error', error)
        return null
      }
    }
  }
})